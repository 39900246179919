<template>
	<div class="w-full">
		<div v-for="(element, index) in sortedObjects" :key="element[itemKey]" class="w-full">
			<div class="flex flex-col justify-start w-full">
				<div class="flex flex-row justify-start items-center w-full">
					<span v-if="level > 1" class="mx-1 flex flex-col items-center justify-start">
						<FontAwesomeIcon :icon="faTurnDownRight" class="h-5 w-5 text-black"></FontAwesomeIcon>
					</span>
					<div
						class="flex flex-row justify-between h-8 rounded pl-2 pr-3 font-normal hover:font-bold my-1 cursor-pointer w-full"
						:class="{
							'bg-primary-800': selectedVariable !== currentVariableAddress(index),
							'bg-primary-600': selectedVariable === currentVariableAddress(index),
						}"
						@click="emits('click', variableAddress ? variableAddress + '.' + index : index, !!element?.attributes?.group)"
					>
						<div class="flex items-center text-white w-full pointer-events-none justify-between">
							<div>
								<FontAwesomeIcon v-if="element?.attributes?.group" :icon="faFolder" class="mr-2"></FontAwesomeIcon>
								<span class="text-lg"> {{ element.name }}</span>
							</div>
							<div>
								<FontAwesomeIcon v-if="element?.attributes?.system && !element?.attributes?.group" :icon="faLockAlt" class="mr-2 text-grey-500 font-semibold align-text-bottom pb-px"></FontAwesomeIcon>
								<FontAwesomeIcon v-if="element?.attributes?.list" :icon="faSquareList" class="mr-2 text-grey-500 font-semibold align-text-bottom"></FontAwesomeIcon>
								<span class="text-sm text-grey-500 font-semibold align-bottom"> {{ element.type ? (element.type.toString() == ActivityTemplateVariableType.DATETIME ? 'DT' : element.type.toString().charAt(0).toUpperCase()) : '' }}</span>
							</div>
						</div>
						<div v-if="element?.attributes?.group" class="flex items-center text-white">
							<FontAwesomeIcon
								:icon="faCaretUp"
								class="transition-all transform duration-75"
								:class="{
									'rotate-180': openGroups.includes(currentVariableAddress(index).toString()),
								}"
							></FontAwesomeIcon>
						</div>
					</div>
				</div>
				<div
					v-if="element?.attributes?.group && openGroups.includes(currentVariableAddress(index).toString())"
					:style="{
						'margin-left': (level > 1 ? 25 : 0) + 'px',
					}"
				>
					<VariableSelector
						v-if="Object.keys(element.children).length > 0"
						:objects="element.children"
						:item-key="itemKey"
						:level="level + 1"
						:folder-name="element.name"
						:variable-address="(variableAddress ? variableAddress + '.' + index : index) + '.children'"
						:selected-variable="selectedVariable"
						:open-groups="openGroups"
						:can-create="canCreate"
						@click="(variableAddressClicked: string, isGroup: boolean) => emits('click', variableAddressClicked, isGroup)"
						@create="(variableAddressToCreateAt: string, isGroup: boolean) => emits('create', variableAddressToCreateAt, isGroup)"
					/>
					<div v-else class="flex flex-row justify-start items-center w-full">
						<span class="mx-1 flex flex-col items-center justify-start">
							<FontAwesomeIcon :icon="faTurnDownRight" class="h-5 w-5 text-black"></FontAwesomeIcon>
						</span>
						<div class="flex flex-row justify-between items-center h-8 bg-gray-200 rounded px-3 font-normal my-1.5 cursor-pointer w-full">
							<span> No variables in this folder... yet</span>
						</div>
					</div>
					<div class="pl-6.5">
						<div v-if="canCreate" class="pl-px flex flex-col justify-start items-center w-full my-0.5 space-y-1">
							<button
								type="button"
								class="flex justify-center items-center w-full rounded-lg border-2 border-dashed font-medium hover:font-bold border-gray-300 hover:border-gray-400 focus:outline-none h-8"
								@click="emits('create', variableAddress ? variableAddress + '.' + index : index, true)"
							>
								<span class="block text-sm text-gray-900">
									<span class="mr-1">
										<FontAwesomeIcon :icon="faPlusLarge"></FontAwesomeIcon>
									</span>
									Create a new folder in "{{ element.name }}"
								</span>
							</button>
							<button
								type="button"
								class="flex justify-center items-center w-full rounded-lg border-2 border-dashed font-medium hover:font-bold border-gray-300 hover:border-gray-400 focus:outline-none h-8"
								@click="emits('create', variableAddress ? variableAddress + '.' + index : index, false)"
							>
								<span class="block text-sm text-gray-900">
									<span class="mr-1">
										<FontAwesomeIcon :icon="faPlusLarge"></FontAwesomeIcon>
									</span>
									Create a new variable in "{{ element.name }}"
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="level == 1 && canCreate" class="flex flex-col justify-start items-center w-full space-y-1 my-1">
			<button type="button" class="flex justify-center items-center w-full rounded-lg border-2 border-dashed font-medium hover:font-bold border-gray-300 hover:border-gray-400 focus:outline-none h-8" @click="emits('create', false, true)">
				<span class="block text-sm text-gray-900">
					<span class="mr-1">
						<FontAwesomeIcon :icon="faPlusLarge"></FontAwesomeIcon>
					</span>
					Create a new "root" folder
				</span>
			</button>
			<button type="button" class="flex justify-center items-center w-full rounded-lg border-2 border-dashed font-medium hover:font-bold border-gray-300 hover:border-gray-400 focus:outline-none h-8" @click="emits('create', false, false)">
				<span class="block text-sm text-gray-900">
					<span class="mr-1">
						<FontAwesomeIcon :icon="faPlusLarge"></FontAwesomeIcon>
					</span>
					Create a new "root" variable
				</span>
			</button>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { computed, defineEmits, defineProps, withDefaults } from 'vue';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faCaretUp, faFolder, faLockAlt, faSquareList } from '@fortawesome/pro-regular-svg-icons';
	import { faPlusLarge, faTurnDownRight } from '@fortawesome/pro-light-svg-icons';
	import { WorkflowVariables } from '@/types/workflow';
	import { ActivityTemplateVariableType } from '@/types/activityTemplate';

	interface Props {
		objects: WorkflowVariables;
		itemKey: string;
		level?: number;
		variableAddress?: string;
		selectedVariable: string;
		folderName?: string | boolean;
		openGroups: string[];
		canCreate?: boolean;
	}

	const props = withDefaults(defineProps<Props>(), {
		itemKey: 'id',
		level: 1,
		variableAddress: '',
		folderName: false,
		canCreate: true,
	});

	const sortedObjects = computed<WorkflowVariables>(() => {
		return props.objects;
	});

	const emits = defineEmits<{
		(e: 'click', address: string, group: boolean): void;
		(e: 'create', address: string | boolean, group: boolean): void;
	}>();

	const currentVariableAddress = (variableIndex: string | number) => {
		return props.variableAddress ? props.variableAddress + '.' + variableIndex : variableIndex;
	};
</script>
